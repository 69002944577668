.ProductsPages {
  width: 75%;
  height: 100%;
  overflow-y: auto;
  .buttonSearchClean {
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin-left: 20px;
  }
  &__full {
    width: 100%;
  }
  h1 {
    padding: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    display: flex;
    align-items: center;
  }
  &__Products {
    width: 100%;
    height: fit-content;
    margin: 0 auto 20px auto;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      width: 32%;
      height: 300px;
      display: block;
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .loadmore {
    width: 200px;
    height: 40px;
    background: #403f41;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px auto;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
}
@media screen and (max-width: 1300px) {
  .ProductsPages {
    &__Products {
      a {
        width: 31%;
      }
    }
  }
}
@media screen and (max-width: 1133px) {
  .ProductsPages {
    width: 100%;
    .loadmore {
      margin: 20px auto;
    }
  }
}
@media screen and (max-width: 768px) {
  .ProductsPages {
    h1 {
      padding: 15px;
      font-size: 14px;
      line-height: 16px;
    }
    &__Products {
      gap: 15px;
      margin-bottom: 15px;
      a {
        width: 48%;
        height: 50vw;
      }
      .loadmore {
        margin: 15px auto;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .ProductsPages {
    &__Products {
      a {
        width: 100%;
        height: 100vw;
      }
    }
  }
}
