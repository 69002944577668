.App {
  width: 100%;
  height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  .Container {
    width: 100%;
    height: calc(100vh - 70px);
  }
}
@media screen and (max-width: 1133px) {
  .App {
    position: relative;
    .Container {
      margin-top: 70px;
    }
    .Container__custom {
      height: calc(100vh - 70px);
      margin-top: 70px;
    }
  }
}
@media screen and (max-width: 768px) {
  .App {
    .Container {
      margin-top: 100px;
      height: 100%;
      overflow-y: auto;
    }
    .Container__custom {
      margin-top: 50px;
    }
  }
}
