.Brands {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  gap: 80px;
}
.BrandsList {
  width: 20%;
  height: 100%;
  ul {
    margin-top: 53%;
    li {
      font-weight: 200;
      font-size: 24px;
      line-height: 28px;
      text-align: right;
      margin-bottom: 15px;
    }
    li:nth-child(2) {
      font-size: 22px;
    }
    li:nth-child(3) {
      font-size: 20px;
    }
    li:nth-child(4) {
      font-size: 18px;
    }
    li:nth-child(5) {
      font-size: 16px;
    }
    li:nth-child(6) {
      font-size: 14px;
    }
    li:nth-child(7) {
      font-size: 14px;
    }
    li:nth-child(8) {
      font-size: 14px;
    }
    li:nth-child(9) {
      font-size: 14px;
    }
    li:nth-child(10) {
      font-size: 14px;
    }
  }
}
.BrandsAbout {
  width: 80%;
  height: 100%;
  display: flex;
  column-gap: 50px;
  &__about {
    width: 50%;
    height: 100%;
    display: block;
    padding-top: 50px;
    &__heading {
      .arrow {
        display: none;
      }
      h1 {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        font-weight: 500;
        font-size: 3.3vw;
        line-height: 100%;
      }
    }
    &__description {
      max-height: 88%;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      margin-top: 40px;
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 200%;
        color: rgba(64, 63, 65, 0.75);
      }
    }
  }
  &__img {
    width: 50%;
    height: 100%;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@media screen and (max-width: 1133px) {
  .Brands {
    gap: 50px;
  }
  .BrandsList {
    ul {
      margin-top: 70%;
    }
  }
  .BrandsAbout {
    &__about {
      &__heading {
        h1 {
          font-size: 40px;
        }
      }
    }
  }
}
@media screen and (max-width: 760px) {
  .Brands {
    padding: 0;
  }
  .BrandsList {
    width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
      margin-top: 0;
      li {
        text-align: center !important;
      }
    }
  }
  .BrandsList.active {
    display: none;
  }
  .BrandsAbout.active {
    width: 100%;
    height: 100%;
    display: flex;
    column-gap: 20px;
    padding: 15px;
  }
  .BrandsAbout {
    display: none;
    &__about {
      width: 48%;
      padding-top: 0px;
      overflow: hidden;
      &__heading {
        width: 100%;
        display: grid;
        grid-template-columns: 36px 1fr;
        .arrow {
          grid-column: 1 / 2;
          display: block;
          width: 36px;
          height: 36px;
          stroke: #403f41;
          cursor: pointer;
        }
        h1 {
          grid-column: 2 / 3;
          width: 100%;
          font-size: 36px;
          text-align: center;
          justify-content: center;
        }
      }
      &__description {
        margin-top: 20px;
        max-height: 80%;
        overflow-y: auto;
        overflow: -moz-scrollbars-none;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
    &__img {
      width: 48%;
      height: calc(100vh - 80px);
      position: fixed;
      right: 15px;
    }
  }
}
@media screen and (max-width: 550px) {
  .Brands {
    gap: 0;
  }
  .BrandsAbout.active {
    overflow-y: auto;
    overflow: -moz-scrollbars-none;
    padding: 0;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .BrandsAbout {
    &__about {
      width: 100%;
      padding: 15px;
      margin-top: 100vw;
      background-color: #fff;
      z-index: 10;
      -webkit-box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.25);
      position: relative;
      .line {
        height: 3px;
        width: 50px;
        position: absolute;
        top: 10px;
        left: calc(50% - 25px);
        background-color: #403f41;
        opacity: 0.2;
        border-radius: 50px;
      }
      &__heading {
        width: 100%;
        margin-top: 20px;
      }
      &__description {
        max-height: 100% !important;
        height: fit-content !important;
        padding-bottom: 100px;
      }
    }
    &__img {
      width: 100%;
      height: 100vw;
      position: fixed;
      right: 0;
      z-index: 0;
    }
  }
}
