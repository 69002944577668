.Catalog {
  width: 100%;
  height: 100%;
  padding: 15px 15px 0 15px;
  display: flex;
  gap: 18px;
  position: relative;
  background-color: #fff;
}
@media screen and (max-width: 1133px) {
  .Catalog {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .Catalog {
    padding: 0px;
  }
}
