.Product {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  gap: 20px;
  &__images {
    width: 50%;
    height: 100%;
    .swiper {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__info {
    width: 50%;
    height: 100%;
    display: grid;
    grid-template: min-content min-content min-content min-content min-content auto / 1fr 250px;
    column-gap: 20px;
    overflow-y: auto;
    &__heading {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      align-self: end;
      width: 100%;
      display: flex;
      align-items: center;
      .buttonBack {
        height: 40px;
        min-width: 40px;
        cursor: pointer;
        z-index: 1;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      h1 {
        font-weight: 500;
        font-size: 3.3vw;
        line-height: 100%;
        text-align: left;
        width: 100%;
      }
    }
    &__brand {
      grid-row: 2 / 6;
      grid-column: 1 / 2;
      height: fit-content;
      align-self: start;
      margin: 20px 0;
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 200%;
        color: rgba(64, 63, 65, 0.75);
      }
    }
    &__about {
      grid-row: 6 / 7;
      grid-column: 1 / 2;
      height: fit-content;
      align-self: start;
      width: 100%;
      padding-bottom: 20px;
      ul {
        display: grid;
        grid-auto-flow: row;
        row-gap: 10px;
        height: fit-content;
        width: 100%;
        li {
          font-weight: 200;
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
    &__price {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      display: grid;
      grid-template-columns: 32px min-content;
      column-gap: 10px;
      align-self: end;
      .buttonLike {
        grid-column: 1 / 2;
        align-self: end;
        width: 32px;
        height: 32px;
        stroke: #403f41;
        cursor: pointer;
      }
      .active {
        fill: #403f41;
      }
      &__num {
        grid-column: 2 / 3;
        width: fit-content;
        display: grid;
        grid-template-rows: min-content 1fr;
        span {
          font-weight: 300;
          font-size: 32px;
          line-height: 100%;
          width: 100%;
          white-space: nowrap;
          text-align: right;
          small {
            width: 100%;
            font-weight: 300;
            font-size: 20px;
            line-height: 120%;
            opacity: 0.5;
          }
        }
      }
    }
    .img {
      min-height: 20px !important;
      min-width: 20px !important;
      stroke: #403f41 !important;
      margin-right: 5px !important;
    }
    .buttonApple {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
      width: 200px;
      height: 40px;
      background: #403f41;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      cursor: pointer;
      margin: 20px 0;
    }
    .button {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: start;
      cursor: pointer;
      background-color: #ffffff;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      opacity: 0.75;
      text-decoration: underline;
    }
    .doctor {
      grid-row: 4 / 5;
      grid-column: 2 / 3;
    }
    .help {
      grid-row: 3 / 4;
      grid-column: 2 / 3;
    }
    .terms {
      grid-row: 5 / 6;
      grid-column: 2 / 3;
    }
  }
}
.modalBackground {
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 100;
  height: calc(100vh - 70px);
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}
.modal {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  height: fit-content;
  width: 50%;
  background-color: #fff;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
  input,
  textarea {
    width: 90%;
    height: 40px;
    border: 1px solid #403f41;
    border-radius: 4px;
    text-align: left;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  textarea {
    padding: 10px 20px;
  }
  select,
  select option {
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }
  select {
    width: 90%;
    height: 40px;
    border: 1px solid #403f41;
    border-radius: 4px;
    margin-bottom: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('./img/arrow-down.svg') no-repeat right;
    background-position-x: calc(100% - 20px);
    background-size: 15px 15px;
    color: rgb(64, 63, 65);
    padding-left: 20px;
  }
  #city {
    width: 90%;
    margin-bottom: 20px;
    text-align: left;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;

    .css-13cymwt-control {
      border: 1px solid #403f41;
      border-radius: 4px;
      height: 40px !important;
      display: flex;
      align-items: center;
    }
    .css-1u9des2-indicatorSeparator {
      display: none;
    }
    .css-tj5bde-Svg {
      opacity: 0;
    }
    .css-1xc3v61-indicatorContainer {
      background: url('./img/arrow-down.svg') no-repeat right;
      background-position-x: calc(100% - 20px);
      background-size: 15px 15px;
    }
    .css-1fdsijx-ValueContainer {
      padding-left: 20px !important;
    }
    input {
      height: 100% !important;
    }
  }
  button,
  .buttonSubmit {
    width: 200px;
    height: 40px;
    background: #403f41;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .modalCloseButton {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 24px;
    width: 24px;
    margin-left: 20px;
    cursor: pointer;
    z-index: 100;
  }
  .buttonHelp__messengers {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 20px auto;
      gap: 5px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    a:last-child {
      margin-bottom: 0px;
    }
  }
}
.modalInfo {
  height: 80%;
  width: 80%;
  margin: 0 auto;
  overflow-y: auto;
  div {
    margin-bottom: 20px;
    width: 100%;
    p {
      width: 100%;
      text-align: left;
      font-weight: 200;
      font-size: 16px;
      line-height: 120%;
      margin-bottom: 10px;
      span {
        font-weight: 400;
        margin-bottom: 5px;
      }
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  div:last-child {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 1133px) {
  .Product {
    &__images {
      width: 60%;
      height: 60vw;
    }
    &__info {
      width: 40%;
      grid-template: min-content min-content min-content min-content min-content min-content / min-content 1fr;
      gap: 20px;
      &__heading {
        grid-row: 1 / 2;
        grid-column: 1 / 3;
        justify-content: start;
        h1 {
          font-size: 40px;
          text-align: left;
        }
      }
      &__brand {
        grid-row: 4 / 5;
        grid-column: 1 / 3;
        width: 100%;
        margin: 0;
      }
      &__about {
        grid-row: 5 / 6;
        grid-column: 1 / 3;
        padding-bottom: 0px;
      }
      &__price {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        grid-template-columns: 24px min-content;
        column-gap: 10px;
        span {
          font-size: 24px;
        }
        .priceSmall {
          font-size: 18px;
        }
        .buttonLike {
          width: 24px;
          height: 24px;
        }
      }
      .buttonApple {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        width: 80%;
        height: 40px;
        align-self: end;
        margin: 0;
      }
      .doctor {
        grid-row: 3 / 4;
        grid-column: 1 / 2;
        width: min-content;
        justify-self: end;
        text-align: right;
      }
      .help {
        grid-row: 3 / 4;
        grid-column: 2 / 3;
        width: 70%;
      }
      .terms {
        grid-row: 6 / 7;
        grid-column: 1 / 3;
        width: 100%;
        justify-content: start;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .Product {
    &__images {
      width: 50%;
      height: 50vw;
    }
    &__info {
      width: 50%;
    }
  }
  .modalBackground {
    height: calc(100vh - 50px);
    top: 50px;
    align-items: start;
    background-color: #fff;
  }
  .modal {
    box-shadow: none;
    width: 100%;
    padding: 30px;
    input {
      width: 80%;
    }
    textarea {
      width: 80%;
    }
    select {
      width: 80%;
    }
    #city {
      width: 80%;
    }
  }
  .modalInfo {
    height: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .Product {
    height: calc(100vh - 50px);
    position: relative;
    padding: 0;
    &__images {
      width: 100%;
      height: 100vw;
      position: fixed;
      z-index: 0;
      top: 50px;
    }
    &__info {
      margin-top: 100vw;
      width: 100%;
      padding: 15px;
      background-color: #fff;
      z-index: 20;
      -webkit-box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.25);
      position: relative;
      grid-template: min-content min-content min-content min-content min-content min-content / 1fr 50%;
      .line {
        height: 3px;
        width: 50px;
        position: absolute;
        top: 10px;
        left: calc(50% - 25px);
        background-color: #403f41;
        opacity: 0.2;
        border-radius: 50px;
      }
      &__heading {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 20px;
        .buttonBack {
          height: 36px;
          width: 36px;
        }
        h1 {
          font-size: 36px;
          margin-left: -36px;
          text-align: center;
        }
      }
      &__about {
        ul {
          li {
            text-align: center;
          }
        }
      }
      &__price {
        justify-self: end;
        column-gap: 15px;
      }
      .buttonApple {
        width: 90%;
      }
      .terms {
        justify-content: center;
      }
    }
  }
  .modal {
    height: 100%;
    justify-content: center;
    input {
      width: 100%;
    }
    textarea {
      width: 100%;
    }
    select {
      width: 100%;
    }
    #city {
      width: 100%;
    }
    .modalCloseButton {
      right: 10px;
      top: 10px;
    }
    .buttonHelp__messengers {
      height: 100%;
      justify-content: center;
    }
  }
  .modalInfo {
    justify-content: start;
  }
}
@media screen and (max-width: 320px) {
  .Product {
    &__info {
      height: fit-content;
      &__price {
        column-gap: 10px;
      }
    }
  }
}
