.Terms {
  width: 100%;
  height: 100%;
  margin-top: 70px;
  padding: 15px;
  display: flex;
  flex-grow: 1;
  &__list {
    width: 60%;
    padding: 50px;
    display: block;
    margin: auto auto;
    overflow-y: auto;
    height: 100%;
    overflow: -moz-scrollbars-none;
    .Collapsible {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
      cursor: pointer;
    }
    .Collapsible:last-child {
      margin-bottom: 0;
    }
    .Collapsible:nth-child(2) .Collapsible__trigger {
      opacity: 0.9;
      font-size: 19px;
    }
    .Collapsible:nth-child(3) .Collapsible__trigger {
      opacity: 0.8;
      font-size: 18px;
    }
    .Collapsible__trigger {
      h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
        text-transform: uppercase;
        color: #403f41;
        text-align: center;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .buttonMinus {
          display: none;
        }
        .buttonPlus,
        .buttonMinus {
          height: 15px;
          width: 15px;
          margin-left: 5px;
          margin-right: -20px;
        }
      }
    }
    .Collapsible__trigger.is-open {
      h3 {
        .buttonPlus {
          display: none;
        }
        .buttonMinus {
          display: flex;
        }
      }
    }
    .Collapsible p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 200;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      color: #000000;
      text-align: left;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .Terms {
    &__list {
      width: 100%;
      max-height: 100%;
      height: 100%;
      margin-top: 50px;
      padding: 50px;
      display: block;
      overflow-y: auto;
      .Collapsible {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
      }
      .certificate {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin: 20px auto;
        width: 100%;
        .modal {
          height: fit-content;
          width: 40%;
          background-color: #fff;
          padding: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          h3 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
            text-transform: uppercase;
            color: #403f41;
            margin-bottom: 20px;
          }
          h4 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
            text-transform: uppercase;
            color: #403f41;
          }
          input {
            width: 90%;
            height: 40px;
            border: 1px solid #403f41;
            border-radius: 4px;
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 200;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: #403f41;
            margin-bottom: 20px;
          }
          textarea {
            width: 90%;
            min-height: 40px;
            border: 1px solid #403f41;
            border-radius: 4px;
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 200;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: #403f41;
            padding: 10px;
            margin-bottom: 20px;
          }
          button {
            width: 200px;
            height: 40px;
            background: black;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
          }
          .modalCloseButton {
            position: absolute;
            right: 20px;
            top: 20px;
            height: 25px;
            width: 25px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .Terms {
    &__list {
      width: 100%;
      padding: 25px;
      display: block;
      .Collapsible {
        margin-bottom: 20px;
      }
    }
  }
}
