.Space {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__Comingsoon {
    position: relative;
    width: 980px;
    height: 420px;
    margin: auto 0;
  }
  &__Comingsoon::before {
    content: '';
    opacity: 0.5;
    background-image: url('./img/comingsoon.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__Comingsoon h4 {
    position: absolute;
    top: 45%;
    left: 60%;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    opacity: 1;
  }
}
@media screen and (max-width: 750px) {
  .Space {
    &__Comingsoon {
      width: 100%;
      h4 {
        font-size: 35px;
        top: 20%;
        left: 55%;
      }
    }
  }
}
@media screen and (max-width: 430px) {
  .Space {
    &__Comingsoon {
      width: 100%;
      h4 {
        font-size: 30px;
        top: 40%;
        left: 55%;
      }
    }
  }
}
