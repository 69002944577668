.Filters {
  width: 25%;
  height: 100%;
  overflow-y: auto;
  padding: 20px 20px 35px 20px;
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 20px;
    width: 100%;
  }
  .closeFilters {
    display: none;
  }
  &__options {
    display: flex;
    flex-direction: column;
    .optionsHeading {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 10px;
      padding: 0;
      text-decoration: underline;
    }
    ul {
      margin-bottom: 30px;
      li {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 5px;
        border: 1px solid transparent;
        border-radius: 4px;
        list-style: none;
        cursor: pointer;
        padding: 1px 3px;
        width: fit-content;
        .buttonClose {
          display: none;
        }
      }
      .active {
        display: flex;
        align-items: center;
        .buttonClose {
          display: block;
          stroke: #403f41;
          width: 10px;
          height: 10px;
          margin-left: 5px;
          padding: 0;
          cursor: pointer;
          border: none;
        }
      }
      :first-child {
        cursor: default;
      }
    }
  }
  &__buttons {
    width: 100%;
    display: flex;
    column-gap: 10px;
    button {
      padding: 5px 10px;
      color: #403f41;
      border-radius: 4px;
      border: 1px solid #403f41;
      background-color: #fff;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
@media screen and (max-width: 1133px) {
  .Filters {
    display: none;
    h4 {
      font-size: 14px;
      line-height: 16px;
      display: flex;
      justify-content: center;
    }
    .closeFilters {
      display: block;
      position: absolute;
      cursor: pointer;
      top: 20px;
      right: 20px;
      img {
        width: 30px;
        height: 30px;
        padding: 5px;
      }
    }
    &__options {
      flex-direction: row;
      ul {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
      }
    }
    &__buttons {
      justify-content: center;
    }
  }
  .Filters.toggled {
    position: fixed;
    z-index: 100;
    margin-top: 15px;
    display: block;
    height: fit-content;
    width: calc(100% - 30px);
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #403f41;
    background-color: #fff;
    left: 15px;
  }
}
@media screen and (max-width: 500px) {
  .Filters {
    overflow-y: auto;
    &__options {
      display: grid;
      grid-template: auto auto / 1fr 50%;
      margin-bottom: 20px;
      ul:first-child {
        margin-bottom: 20px;
      }
    }
  }
}
