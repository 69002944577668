.Nav {
  width: 33.3%;
  height: 100%;
  display: flex;
  .bm-burger-button {
    width: 0px;
    position: relative;
  }
  ul {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    li {
      a {
        cursor: pointer;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: white;
      }
    }
  }
  a.active,
  a:hover {
    border-bottom: solid 1px;
  }
}
@media screen and (max-width: 1133px) {
  .Nav {
    width: 100%;
    position: relative;
    align-items: center;
    ul {
      display: none;
    }
    .burgerIcon {
      stroke: white;
    }
    .burgerClose {
      display: block;
      stroke: white;
    }
    .bm-cross-button {
      top: 20px !important;
      right: 20px !important;
    }
    .bm-burger-button {
      position: relative;
      cursor: pointer;
      background: transparent;
      width: 24px;
      height: 24px;
    }
    .bm-menu-wrap {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 300px;
      z-index: 300;
    }
    .bm-morph-shape {
      fill: #403f41;
    }
    .bm-menu {
      width: 100%;
    }
    .burgerMenu {
      height: 100vh !important;
    }
    .bm-item-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      padding: 80px 50px;
    }
    .bm-item {
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: white;
    }
    .bm-item.socials {
      display: flex !important;
      gap: 20px;
    }
    .bm-overlay {
      background-color: transparent !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .Nav {
    .bm-menu-wrap {
      width: 100% !important;
    }
    .bm-item-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 30px;
    }
  }
}
