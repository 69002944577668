.Contacts {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row-reverse;
  overflow: -moz-scrollbars-none;
  &__map {
    width: 50%;
    height: 100%;
  }
  &__adress {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    padding: 50px;
    overflow-y: auto;
    p,
    a {
      font-weight: 200;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
      text-align: center;
      display: block;
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 768px) {
  .Contacts {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: fit-content;
    &__map {
      width: 100%;
      height: 400px;
    }
    &__adress {
      width: 100%;
      height: fit-content;
      margin: 20px 0;
      padding: 0;
      gap: 20px;
    }
  }
}
