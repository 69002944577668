.Header {
  width: 100%;
  height: 70px;
  padding: 15px;
  background-color: #403f41;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  &__filters {
    display: none;
  }
  &__icons {
    width: 33.3%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end !important;
    column-gap: 20px;
    &__search {
      width: 200px;
      height: 24px;
      display: flex;
      align-items: center;
      input[type='search'] {
        width: 174px;
        height: 100%;
        background-color: transparent;
        padding: 4px 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #fff;
        border-bottom: 1px solid #fff;
        border-radius: 0;
      }

      input[type='search']::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
      }
      input[type='search']::-moz-cancel-button {
        display: none;
      }
      .buttonSearch {
        width: 24px;
        height: 24px;
        stroke: #fff;
      }
      .buttonSearchClean {
        width: 16px;
        height: 16px;
        stroke: #fff;
        cursor: pointer;
      }
    }
    img {
      width: 24px;
      height: 24px;
    }
    .buttonLike {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  &__logo {
    width: 33.3%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      height: 40px;
      width: auto;
      object-fit: contain;
    }
  }
  .mobileLike {
    display: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
@media screen and (max-width: 1440px) {
  .Header {
    border-radius: 0;
  }
}
@media screen and (max-width: 1133px) {
  .Header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    &__logo {
      justify-self: center;
      width: 100%;
      img {
        height: 35px;
      }
    }
    .Nav {
      width: fit-content;
    }
    &__filters {
      width: 100px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #fff;
      background-color: transparent;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin: 0 15px;
    }
    &__filters.active {
      animation: 2s ease-out;
      animation-iteration-count: infinite;
    }
    @keyframes pulsate_yellow {
      0% {
        box-shadow: 0 0 0 0 #f4b710;
      }

      50% {
        box-shadow: 0 0 0 10px rgba(244, 183, 16, 0);
      }
    }
    @keyframes pulsate_mint {
      0% {
        box-shadow: 0 0 0 0 #a0d1c7;
      }

      50% {
        box-shadow: 0 0 0 10px rgba(244, 183, 16, 0);
      }
    }
    @keyframes pulsate_pink {
      0% {
        box-shadow: 0 0 0 0 #f08bb8;
      }

      50% {
        box-shadow: 0 0 0 10px rgba(244, 183, 16, 0);
      }
    }
    .mobileLike {
      display: block;
    }
    &__icons {
      width: fit-content;
      justify-self: end;
      margin: 0 15px;
      .buttonInstagram {
        display: none;
      }
      .buttonTiktok {
        display: none;
      }
      .buttonLike {
        display: none;
      }
      &__search {
        width: 124px;
        input[type='search'] {
          width: 100px;
        }
      }
    }
  }
  .Header__custom {
    height: 70px;
    display: grid;
    grid-template: 100% / 24px 1fr 24px;
    align-items: center;
    &__logo {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
    .Nav {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
    .mobileLike {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
    }
    .Header__filters {
      display: none;
    }
    .Header__icons {
      display: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .Header {
    height: 100px;
    display: grid;
    grid-template: 50% 1fr / 24px 1fr 24px;
    padding: 0 15px;
    &__logo {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      align-content: end;
      width: 100%;
      img {
        height: 30px;
      }
    }
    .Nav {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      width: 24px;
    }
    &__filters {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      margin: 0px;
    }
    .mobileLike {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
    }
    &__icons {
      grid-column: 2 / 4;
      grid-row: 2 / 3;
      margin: 0px;
    }
  }
  .Header__custom {
    height: 50px;
    grid-template: 100% / 24px 1fr 24px;
    align-items: center;
  }
}
@media screen and (max-width: 320px) {
  .Header {
    &__logo {
      img {
        height: 25px;
      }
    }
  }
}
